<template>
  <el-collapse v-model="activeNames" accordion>
    <el-collapse-item name="1">
      <template #title>
        {{ $t('筛选') }}<i class="header-icon el-icon-info" style="margin-left: 3px;"></i>
      </template>
      <div>
        <el-form :model="ruleForm" size="small" label-width="90px" @keydown.enter="checkStat">
          <el-row :gutter="35">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb15">
              <el-input
                  v-model="ruleForm.account_user"
                  :placeholder="$t('分机号')"
                  size="small" class="input-with-select"
                  clearable
              >
                <template #prepend>
                  <el-checkbox v-model="ruleForm.dep" :label="$t('部门')" :false-label="0" :true-label="1">
                  </el-checkbox>
                </template>
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb15">
              <el-date-picker size="small" :shortcuts="shortcuts" v-model="value1" type="daterange"
                              range-separator="-"
                              :start-placeholder="$t('开始时间')" :end-placeholder="$t('结束时间')"
                              :editable="false" :clearable="false"
                              format="YYYY-MM-DD" value-format="YYYY-MM-DD"
                              @calendar-change="calendar" :disabled-date="disableddate"
                              @change="changeOncick" style="width:100%">
              </el-date-picker>
            </el-col>
          </el-row>
          <div class="inquire">
            <p>{{ $t('双击部门所在的行进入部门分机统计') }}</p>
            <el-button size="small" type="info" class="inquire-btn" @click="exportStat">
              {{ $t('导出') }}
            </el-button>
            <el-button size="small" type="primary" class="inquire-btn" @click="checkStat">
              {{ $t('查询') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </el-collapse-item>
  </el-collapse>

</template>

<script>
import {
  reactive,
  toRefs,
  ref, onMounted,
} from 'vue';
import {useI18n} from "vue-i18n";
import moment from "moment";
import {
  useStore
} from 'vuex';
import emitter from "@/utils/eventBus";
import {expStat} from "@/http/recordsCenter.api";
import {ElNotification} from "element-plus";

export default {
  name: 'callStatistics',
  setup() {
    const {t}   = useI18n()
    const state = reactive({
      ruleForm: {
        name: '',
        account_user: '',
        dep: 0,
        depId: '00000000-0000-0000-0000-000000000000',
      },
      activeName: 'first',
      tabPosition: 'left',
      shortcuts: [{
        text: t('当天'),
        value: () => {
          const end   = moment().format('YYYY-MM-DD')
          const start = moment(new Date().getTime()).format('YYYY-MM-DD')
          return [start, end]
        },
      },
        {
          text: t('昨天'),
          value: () => {
            const end   = moment(new Date().getTime() - 3600 * 24 * 1000).format('YYYY-MM-DD')
            const start = moment(new Date().getTime() - 3600 * 24 * 1000).format('YYYY-MM-DD')
            return [start, end]
          },
        },
        {
          text: t('本周'),
          value: () => {
            const end   = moment().format('YYYY-MM-DD')
            const start = moment().startOf('isoweek').format('YYYY-MM-DD')
            return [start, end]
          },
        },
        {
          text: t('上周'),
          value: () => {
            const end   = moment().endOf('isoweek').subtract('week', 1).format('YYYY-MM-DD')
            const start = moment().startOf('isoweek').subtract('week', 1).format('YYYY-MM-DD')
            return [start, end]
          },
        },
        {
          text: t('本月'),
          value: () => {
            const end   = moment().format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('month').format('YYYY-MM-DD')
            return [start, end]
          },
        },
        {
          text: t('上月'),
          value: () => {
            const end   = moment().endOf('month').subtract('month', 1).endOf('month').format('YYYY-MM-DD')
            const start = moment().startOf('month').subtract('month', 1).format('YYYY-MM-DD')
            return [start, end]
          },
        },
      ],
      value1: '',
    });
    onMounted(() => {
      let newDate   = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/,
          '');
      let strDate   = new Date(new Date(new Date().toLocaleDateString()).getTime());
      var startTime = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      let arr       = [];
      arr.push(startTime)
      arr.push(newDate)
      state.value1      = arr;
      state.clientWidth = window.innerWidth;

      store.state.stat.dep          = state.ruleForm.dep;
      store.state.stat.account_user = state.ruleForm.account_user;
      store.state.stat.name         = state.ruleForm.name;
      store.state.stat.value1       = arr;
    })

    function export_stat() {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        account_user: state.ruleForm.account_user,
        dep: state.ruleForm.dep,
        name: state.ruleForm.name,
        dep_id: state.ruleForm.depId,
      }
      expStat(params).then(res => {
        if (res.code == 200) {
          const a         = document.createElement("a");
          a.href          = res.data.file_name;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    const store   = useStore()
    var switchDep = false;
    let checkStat = () => {
      store.state.stat.dep          = state.ruleForm.dep;
      store.state.stat.account_user = state.ruleForm.account_user;
      store.state.stat.value1       = state.value1;
      if (state.ruleForm.dep == 1) {
        store.commit('department_open')
        state.ruleForm.name     = '';
        state.ruleForm.name_tag = '';
        state.ruleForm.depId    = '00000000-0000-0000-0000-000000000000';
        if (switchDep) {
          emitter.emit("refresh_dep", state);
        }
        switchDep = true
      } else {
        store.commit('department_close')
        emitter.emit("refresh_ext", state);
        switchDep = false
      }
    }

    let exportStat    = () => {
      store.state.stat.dep          = state.ruleForm.dep;
      store.state.stat.account_user = state.ruleForm.account_user;
      store.state.stat.value1       = state.value1;
      if (state.ruleForm.dep == 1) {
        state.ruleForm.name  = '';
        state.ruleForm.depId = '00000000-0000-0000-0000-000000000000';
      }
      export_stat();
    }
    const activeNames = ref(['1'])

    //时间范围选择
    function disableddate(time) {
      const nowDate = new Date();
      if (state.value2) {
        let isSelected = false;
        // const startDay = (new Date(state.value2).getDate() - 1) * 24 * 3600 * 1000;
        // const endDay   = (new Date(
        //     new Date(state.value2).getFullYear(),
        //     new Date(state.value2).getMonth() + 1,
        //     0
        // ).getDate() - new Date(state.value2).getDate()) * 24 * 3600 * 1000;
        // let minTime = state.value2 - startDay;
        // let Timer   = minTime + startDay;
        return isSelected || time.getTime() > nowDate
      } else {
        return time.getTime() > nowDate;
      }
    }

    //获取开始时间并赋值给value2
    function calendar(arr) {
      state.value2 = arr[0];
    }

    //确认取消value2值
    function changeOncick() {
      state.value2 = '';
    }

    return {
      ...toRefs(state),
      activeNames,
      disableddate,
      calendar,
      changeOncick,
      checkStat,
      exportStat
    };
  },
};
</script>
<style lang="scss">
.el-collapse {
  margin: 15px 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
}

.inquire {
  display: flex;
  margin-right: 10px;

  p {
    font-size: .8rem;
    color: #99abb4;
  }
}

.inquire-btn {
  margin-left: auto;
}

.el-form-item__label {
  padding: 0 12px 0 0 !important;
}
</style>
