<template>
  <div>
    <el-button size="small" icon="el-icon-arrow-left" type="primary" plain v-if="isSub" @click="jumpDep">
      {{ $t('返回上一级部门') }}
    </el-button>
    <el-table :data="statData" stripe style="width: 100%" class="table"
              id="out-table" @row-dblclick="tabClick">
      <el-table-column prop="number" :label="$t('分机')" show-overflow-tooltip v-if="isShow"
                       width="100"></el-table-column>
      <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip v-if="isShow" width="70"></el-table-column>
      <el-table-column prop="number" :label="$t('部门')" show-overflow-tooltip v-if="showDep"
                       width="100"></el-table-column>
      <el-table-column border :label="$t('呼入')">
        <el-table-column prop="inbound_duration_txt" :label="$t('通话时长')" show-overflow-tooltip
                         width="120"></el-table-column>
        <el-table-column prop="inbound_times" :label="$t('总次数')" show-overflow-tooltip width="70"></el-table-column>
        <el-table-column prop="inbound_success_times" :label="$t('通话次数')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="inbound_success_ratio" :label="$t('接听比率')" show-overflow-tooltip
                         width="100"></el-table-column>
        <el-table-column prop="inbound_avg_txt" :label="$t('平均时长')" show-overflow-tooltip
                         width="140"></el-table-column>
      </el-table-column>
      <el-table-column border :label="$t('呼出')">
        <el-table-column prop="outbound_duration_txt" :label="$t('通话时长')" show-overflow-tooltip
                         width="120"></el-table-column>
        <el-table-column prop="outbound_times" :label="$t('总次数')" show-overflow-tooltip width="70"></el-table-column>
        <el-table-column prop="outbound_success_times" :label="$t('通话次数')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="outbound_success_ratio" :label="$t('接听比率')" show-overflow-tooltip
                         width="100"></el-table-column>
        <el-table-column prop="outbound_avg_txt" :label="$t('平均时长')" show-overflow-tooltip
                         width="140"></el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange" class="mt15"
                   :pager-count="5" :page-sizes="[10, 20, 30]" :current-page="pageNum" background :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>
<script>
import {
  toRefs,
  reactive, onMounted
} from 'vue';
import {getStat} from "@/http/recordsCenter.api";
import {useStore} from "vuex";
import emitter from "@/utils/eventBus";
import {ElNotification} from "element-plus";
import {useI18n} from "vue-i18n";

export default {
  name: 'departmentForm',
  setup(props, context) {
    const state = reactive({
      ruleForm: {
        account_user: '',
        dep: 0,
        name: '',
        name_tag: '',
        depId: "00000000-0000-0000-0000-000000000000",
      },
      value1: [],
      statData: [],
      subDep: '',
      isShow: 0,
      isSub: 0,
      showDep: 1,
      pageSize: 5,     //每页数据条数
      pageNum: 1,       //页码
      total: 0,        //总数据条数
      pageCount: 0,    //总页数
      clientWidth: '',
      chartTitle: [],
      chartList: {
        in_times: [],
        in_duration: [],
        out_times: [],
        out_duration: [],
      },
    })
    const {t}   = useI18n();
    onMounted(() => {

      const store                 = useStore()
      state.value1                = store.state.stat.value1;
      state.ruleForm.dep          = store.state.stat.dep;
      state.ruleForm.account_user = store.state.stat.account_user;
      state.ruleForm.name         = store.state.stat.name;
      statList(state);
      state.clientWidth = window.innerWidth;

      emitter.on("refresh_dep", e => {
        state.ruleForm.depId        = e.ruleForm.depId;
        state.ruleForm.dep          = e.ruleForm.dep;
        state.ruleForm.name         = e.ruleForm.name;
        state.ruleForm.name_tag     = e.ruleForm.name_tag;
        state.ruleForm.account_user = e.ruleForm.account_user;
        state.value1                = e.value1;
        statList(state);
      });
    })
    const statList              = (state) => {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        account_user: state.ruleForm.account_user,
        dep: state.ruleForm.dep,
        name: state.ruleForm.name,
        name_tag: state.ruleForm.name_tag,
        dep_id: state.ruleForm.depId,
        page: state.pageNum
      }
      getStat(params).then(res => {
        if (res.code == 200) {
          if (res.data.list.length > 0 && res.data.list[0].ext_open) {
            state.isShow          = 1;
            state.showDep         = 0;
            res.data.list[0].name = "";
          } else {
            state.isShow  = 0;
            state.showDep = 1;
          }
          if (res.data.parent_id != '') {
            state.isSub = 1;
          } else {
            state.isSub = 0;
          }
          state.statData  = res.data.list;
          state.pageCount = res.data.pages;
          state.pageSize  = res.data.length;
          state.total     = res.data.rows;
          state.subDep    = res.data.parent_id;

          state.chartTitle             = res.data.chart_title;
          state.chartList.in_times     = res.data.chart_list.in_times;
          state.chartList.in_duration  = res.data.chart_list.in_duration;
          state.chartList.out_times    = res.data.chart_list.out_times;
          state.chartList.out_duration = res.data.chart_list.out_duration;

          context.emit('changeStatTag', res.tag);
          emitter.emit("refresh_chart", state);
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }
    const print                 = () => {
      statList(state);
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      statList(state);
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      statList(state);
    };
    //双击行更改
    const tabClick              = (row) => {
      if (row.name != "total" && row.ext_open == 0) {
        state.ruleForm.depId    = row.name;
        state.ruleForm.name     = row.name;
        state.ruleForm.name_tag = row.name_tag;
        statList(state);
      }
    }
    const jumpDep               = () => {
      state.ruleForm.depId    = state.subDep
      state.ruleForm.name_tag = '';
      statList(state);
    }
    return {
      ...toRefs(state),
      jumpDep,
      onHandleSizeChange,
      onHandleCurrentChange,
      tabClick,
      print
    };
  }
}
</script>
<style lang="scss">
.mt15 {
  text-align: center;
  margin-top: 15px;
}
</style>
