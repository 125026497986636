<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div class="system-user-container">
    <el-card shadow="hover">
      <h3 class="title">{{ $t('通话统计') }}</h3>
      <CallStatistics/>
      <!--      <div class="system-user-search mb15">-->
      <!--        <div>-->
      <!--          <div>-->
      <!--            <el-button size="small" type="primary" class="ml10" @click="exportStat">{{ $t('导出') }}</el-button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <el-tabs v-model="activeTab" type="card" @tab-click="handleClick">
        <el-tab-pane :label="$t('列表')" name="list">
          <DepartmentForm v-if="bumen" @changeStatTag="changeStatTag"/>
          <ExtensionForm v-else @changeStatTag="changeStatTag"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('图表')" name="chart">
          <el-row :gutter="15">
            <el-col :span="24" class="mb15">
              <div :style="{width:echartWidth,height: '300px'}" ref="homeLaboratoryRef"></div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
  nextTick,
  computed
} from 'vue';
import {useI18n} from "vue-i18n";
import CallStatistics from './Components/callStatistics.vue';
import DepartmentForm from './Components/departmentForm.vue';
import ExtensionForm from './Components/extensionForm.vue';
import * as echarts from 'echarts';
import {
  useStore
} from 'vuex';
import emitter from "@/utils/eventBus";
import router from "@/router";

export default {
  name: 'callTimeRecorder',
  components: {
    CallStatistics,
    DepartmentForm,
    ExtensionForm
  },
  setup() {
    const {proxy} = getCurrentInstance();
    const {t}     = useI18n()
    let state     = reactive({
      sysTitle: '',
      myCharts: [],
      chartTitle: [],
      chartList: {
        in_times: [],
        in_duration: [],
        out_times: [],
        out_duration: [],
      },
      activeTab: "list",
      echartWidth: "100%"
    });
    //筛选部门
    const store   = useStore();
    const bumen   = computed(() => store.state.department);

    emitter.on("refresh_chart", e => {
      state.chartTitle             = e.chartTitle;
      state.chartList.in_times     = e.chartList.in_times;
      state.chartList.in_duration  = e.chartList.in_duration;
      state.chartList.out_times    = e.chartList.out_times;
      state.chartList.out_duration = e.chartList.out_duration;
      handleClick();
    });

    const initHomeLaboratory   = () => {
      nextTick(() => {
        const myChart = echarts.init(proxy.$refs.homeLaboratoryRef);
        const option  = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            data: [...state.chartTitle]
          }],
          yAxis: [{
            type: 'value',
            name: t('次数'),
            position: 'left',
            axisLabel: {
              formatter: '{value} ' + t('次')
            }
          },
            {
              type: 'value',
              name: t('时长秒'),
              position: 'right',
            }
          ],
          series: [{
            name: t('呼入时长'),
            type: 'bar',
            yAxisIndex: 1,
            emphasis: {
              focus: 'series'
            },
            data: [...state.chartList.in_duration]
          },
            {
              name: t('呼出时长'),
              type: 'bar',
              stack: 'Ad',
              yAxisIndex: 1,
              emphasis: {
                focus: 'series'
              },
              data: [...state.chartList.out_duration]
            },
            {
              name: t('呼入次数'),
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [...state.chartList.in_times]
            },
            {
              name: t('呼出次数'),
              type: 'bar',
              stack: 'Search Engine',
              emphasis: {
                focus: 'series'
              },
              data: [...state.chartList.out_times]
            }
          ]
        };
        myChart.setOption(option);
        state.myCharts.push(myChart);
      })

    };
    // 批量设置 echarts resize
    const initEchartsResizeFun = () => {
      if (state.activeTab == 'chart') {
        nextTick(() => {
          let parent        = proxy.$refs.homeLaboratoryRef.offsetParent;
          state.echartWidth = parent.offsetWidth + "px";
          for (let i = 0; i < state.myCharts.length; i++) {
            state.myCharts[i].resize();
          }
        });
      }
    }
    // 批量设置 echarts resize
    const initEchartsResize    = () => {
      window.addEventListener('resize', initEchartsResizeFun);
    };

    //点击图表
    function handleClick() {
      if (state.activeTab == 'chart') {
        nextTick(() => {
          let parent        = proxy.$refs.homeLaboratoryRef.offsetParent;
          state.echartWidth = parent.offsetWidth + "px";
          initHomeLaboratory();
        })
      }
    }

    const changeStatTag = (msg) => {
      if (msg) {
        state.sysTitle = msg;
        router.push('/setUp/systemSetup');
      } else {
        state.sysTitle = '';
      }
    }

    onMounted(() => {
      initEchartsResize();
    });
    return {
      ...toRefs(state),
      handleClick,
      changeStatTag,
      bumen
    };
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ml10 {
  margin-left: 10px;
}
</style>
